body {
  font-size: 1em;
  font-family: "Work Sans", sans-serif;
  scroll-behavior: smooth;
  background: #fbfbfb; }

h1,
h2,
h3,
h4,
h5 {
  font-style: normal;
  color: #000; }

h1 {
  font-size: 2em;
  letter-spacing: -2px;
  font-weight: 400; }

h2 {
  font-size: 1.75em; }

h3 {
  color: #333333; }

p {
  font-family: "Merriweather", serif;
  font-size: 1em; }

.intro .container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px 0; }

.intro em {
  border-bottom: 2px solid #333;
  color: #ff0283; }

.intro p {
  font-size: 1.7em;
  line-height: 1.5; }

footer {
  position: relative;
  width: 100%;
  padding: 20px 0; }

.my-masonry-grid {
  display: -webkit-box;
  /* Not needed if autoprefixing */
  display: -ms-flexbox;
  /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px;
  /* gutter size offset */
  width: auto; }

.my-masonry-grid_column {
  padding-left: 30px;
  /* gutter size */
  background-clip: padding-box; }

/* Style your items */
.my-masonry-grid_column > div {
  margin-bottom: 30px; }

header .container {
  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  header .container a {
    color: inherit; }
  header .container nav {
    display: flex; }
    header .container nav .nav-item {
      margin-right: 20px; }
      header .container nav .nav-item img {
        width: 17px; }

.post {
  margin-bottom: 30px;
  max-width: 100%; }
  .post h2 {
    font-family: "Saira Extra Condensed", sans-serif;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    font-size: 1.4em; }
  .post img {
    width: 100%; }
  .post .description {
    text-align: left;
    padding-top: 10px; }
  .post p.tags {
    padding: 0;
    margin: 0;
    text-decoration: underline;
    margin-right: 5px;
    color: gray;
    display: block; }
  .post .tag-container {
    display: flex;
    flex-wrap: wrap; }
